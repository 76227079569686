// Language Dropdown //

button {
  &:disabled {
    background: transparent;
    opacity: 0.7;
  }
}

.languageDropdownWrapper {
  button {
    padding: 0;
    border: none;

    > span {
      position: relative;
      width: 2.6rem;
      height: 2.6rem;
      padding: 0;
      margin: 0;
      border: 0.2rem solid #ffffff;
      border-radius: 50%;
      overflow: hidden;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 3.2rem;
        width: 3.3600000000000003rem !important;
        margin: 0 !important;
        transform: translate(-50%, -50%);
      }
    }

    &:focus {
      outline: none;
    }

    &:after {
      //transform: rotate(180deg);
    }
  }

  ul {
    padding: 0.24rem 0;
    bottom: 3.2rem;
    left: 0;
    right: unset;
    z-index: 99;

    li {
      color: #000000;

      img {
        position: static;
        width: 2.4rem !important;
        margin-right: 0.8rem !important;
      }
    }
  }

  &.alignRight {
    ul {
      right: 0;
      left: unset;
    }
  }

  &.isDarkBackground {
    button::after {
      border-top-color: #ffffff;
      border-bottom-color: #ffffff;
    }
  }
}

.languageDropdownWrapper.navigation {
  margin-left: 1.6rem;
  margin-right: auto;
  margin-top: 0.5rem;

  ul {
    bottom: -14.4rem;
  }
}
