// Global Variables //

//colors:
$color-pink-lightest: #fff2f5;
$color-pink-lighter: #ed426f;
$color-pink: #e5476e;
$color-pink-dark: #a31737;
$color-pink-opacity-94: #ce1847f0;
$color-pink-opacity-50: #ce184780;
$color-white: #ffffff;
$color-white-dark: #f4f4f4;
$color-white-opacity-20: #ffffff33;
$color-white-opacity-50: #ffffff80;
$color-white-opacity-40: #ffffff66;
$color-white-darker: #f1f1f1;
$color-grey-light: #dfdfdf;
$color-grey-lighter: #a5a5a5;
$color-grey-extra-lighter: #A2A2A2;
$color-grey: #605f5f;
$color-grey-medium: #808080;
$color-grey-dark: #7e7e7e;
$color-grey-violet: #9a94a1;
$color-grey-light2: #f8f8f8;
$color-grey-light3: #d6d6d6;
$color-grey-light4: #e1e1e1;
$color-grey-light5: #e4e4e4;
$color-green: #29C400;
$color-green-opacity-10: #31c3011A;
$color-green-medium: #2e9404;
$color-green-dark: #176200;
$color-violet-lighter: #453156;
$color-violet-light: #392747;
$color-violet-medium: #321b44;
$color-violet-dark: #241034;
$color-black-lightest: #383838;
$color-black-lighter: #222222;
$color-black-light: #292929;
$color-black: #000000;
$color-black-opacity-10: #0000001A;
$color-black-opacity-20: #00000033;
$color-black-opacity-30: #0000004D;
$color-black-opacity-40: #00000066;
$color-black-opacity-50: #00000080;
$color-black-opacity-60: #00000099;
$color-red: #d51446;
$color-orange: #FE601C;
$color-orange-opacity-10: #FE601C1A;

// theme
$color-primary: $color-violet-medium;
$color-accent: $color-pink;
$color-accent-light: $color-pink-lighter;
$color-accent-disabled: $color-pink-opacity-50;

$color-error: $color-red;

$color-button-gray: $color-white-darker;
$color-button-gray-hover: $color-grey-light5;
$color-border-gray: #e8e8e8;