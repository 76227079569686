@font-face {
  font-family: 'player-icons';
  src: url('../../icons/player/icomoon.eot?rgak1z');
  src: url('../../icons/player/icomoon.eot?rgak1z#iefix') format('embedded-opentype'),
  url('../../icons/player/icomoon.ttf?rgak1z') format('truetype'),
  url('../../icons/player/icomoon.woff?rgak1z') format('woff'),
  url('../../icons/player/icomoon.svg?rgak1z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
