// Fitney Footer Coronasummer //

.footerWrapper {
  background-color: #f9f9f9;

  .contentWrapper {
    font-family: Poppins, Roboto, 'sans-serif';
    max-width: 136rem;
    margin: 0 auto;
    padding: 3.2rem 3rem;
  }

  .logoAndDropdownWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 12.2rem;
    }
  }

  .newsletterWrapper {
    > div {
      display: flex;
      align-items: center;
      margin-top: 4.6rem;
      border-bottom: 0.1rem solid #e0325f;
    }

    label {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      width: 100%;
      margin: 0;

      > span {
        opacity: 0.4;
      }
    }

    input {
      display: block;
      background: transparent;
      border: none;
      width: 100%;
      font-size: 2.4rem;
      padding: 1.2rem 0;
    }

    button {
      border: none;
      background: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.4rem;
      height: 3.4rem;
      margin-left: 0.8rem;
      margin-top: 1.4rem;

      i {
        color: #e0325f;

        &.buttonDisabled {
          opacity: 0.5;
        }
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .errorMessage {
      color: #d51446;
      font-size: 1.4rem;
      margin-top: 0.8rem;
    }

    .newsletterSubtitle {
      margin-top: 2.2rem;
      font-size: 1.2rem;
    }
  }

  .linksWrappers {
    display: flex;
    font-size: 1.2rem;

    span {
      font-size: 1.2rem;
    }

    .linksGroupTitle {
      text-transform: uppercase;
      color: #000000;
      opacity: 0.4;
    }

    ul {
      padding-inline-start: 0;
      list-style-type: none;
      flex-basis: 50%;
      margin-top: 5.6rem;

      li {
        margin: 1.8rem 0;

        a {
          color: rgb(33, 37, 41);
        }

        &:hover {
          cursor: pointer;

          a {
            text-decoration: none;
          }
        }
      }

      .partnerProgramTooltip {
        position: relative;
        -webkit-transform: translateZ(0); /* webkit flicker fix */
        -webkit-font-smoothing: antialiased; /* webkit text rendering fix */

        .tooltip {
          position: absolute;
          display: block;
          top: 4rem;
          right: -15%;
          background: #ffffff;
          color: #000000;
          opacity: 0;
          padding: 0.72rem 0 1.2rem;
          pointer-events: none;
          width: 26rem;
          transform: translateY(1rem);
          transition: all .25s ease-out;
          box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.25);
          text-align: center;
          border-radius: 0.4rem;

          span:first-of-type {
            font-size: 1.36rem;
            line-height: 2.3;
          }

          span:last-of-type {
            font-size: 1.2rem;

            .emailLink {
              color: #e0325f;
            }
          }

          .tooltipArrow {
            width: 5rem;
            height: 2.4rem;
            position: absolute;
            top: -2.4rem;
            left: 50%;
            transform: translateX(-50%) rotate(-180deg);
            overflow: hidden;

            &::after {
              content: "";
              position: absolute;
              width: 1.6rem;
              height: 1.6rem;
              background: #ffffff;
              transform: translateX(-50%) translateY(-50%) rotate(45deg);
              top: 0;
              left: 50%;
              box-shadow: 0 0 1rem -0.2rem rgba(0, 0, 0, 0.5);
            }
          }

          &:before {
            bottom: -2rem;
            content: " ";
            display: block;
            height: 2rem;
            left: 0;
            position: absolute;
            width: 100%;
          }

          @media (min-width: 768px) {
            right: 16rem;
          }
        }

        &:hover .tooltip {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0rem);
        }
      }
    }
  }

  .socialMediaLinks {
    display: flex;
    align-items: center;
    margin-top: 7.2rem;

    .followUs {
      text-transform: uppercase;
      font-size: 1.2rem;
      color: #000000;
      opacity: 0.4;
      margin-right: 0.72rem;
    }

    .iconLink {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.4rem;

      &:hover {
        text-decoration: none;
      }

      i {
        color: #cecece;
        font-size: 2.16rem;
      }

      span {
        margin-left: 1.2rem;
        font-size: 1.2rem;
        color: #000000;
      }
    }
  }

  .copyright {
    font-size: 1.2rem;
    opacity: 0.3;
    color: #000000;
    margin: 3.2rem 0 0;
  }

  @media (min-width: 1025px) {
    .contentWrapper {
      padding: 6.4rem 3rem 11.2rem;
      display: flex;

      > div {
        flex-basis: 50%;
      }
    }

    .logoAndDropdownWrapper {
      justify-content: flex-start;

      img {
        margin-right: 2.4rem;
      }
    }

    .newsletterWrapper {
      margin-right: 8.6rem;
    }

    .links {
      display: flex;

      > div:first-of-type {
        flex-basis: 66%;
      }

      .linksWrappers {
        display: flex;
        font-size: 1.2rem;

        span {
          font-size: 1.2rem;
        }

        ul {
          margin-top: 0;
          position: relative;

          .partnerProgramTooltip {

            .tooltip {
              left: -35%;
            }

            span:first-of-type {
              font-size: 1.4rem;
            }

            span:last-of-type {
              font-size: 1.2rem;
            }
          }
        }
      }

      .socialMediaLinks {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;

        .iconLink {
          margin-top: 1.36rem;
        }
      }
    }
  }
}
