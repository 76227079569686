@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/grid';

.main {
  min-width: 100vw;
  min-height: 100vh;
  padding: 1.6rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  .header {
    width: 100%;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    padding-top: 4rem;

    .header {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
