@import '../../../styles/abstracts/grid';

// Default App Layout //

// App wrapper
.appWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;

  // main component
  .mainComponentWrapper {
    flex: 1;
    width: 100%;
    max-width: 118rem;
    margin: 0 auto;
    padding: 7rem 0 8.8rem;
    transition: margin 0.1s ease-in-out;
  }

  @media (min-width: $breakpoint-desktop) {
    position: relative;
    padding: 0 1.6rem;

    .mainComponentWrapper {
      height: auto;
      padding: 0;
    }
  }
}
