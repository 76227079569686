@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/grid';

// Footer in App //

.footerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 118rem;
  min-height: 8rem;
  margin: 0 auto;
  padding: 0 1.6rem;

  svg {
    vertical-align: middle;
  }

  .copyright {
    color: $color-black-opacity-40;
    white-space: nowrap;
    font-size: 1.4rem;
    margin-left: 0.4rem;
  }

  .supportLink {
    font-size: 1.4rem;
    line-height: 1.93;
    color: $color-grey-dark;
    margin-right: 1.84rem;

    &:hover {
      color: $color-grey;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $breakpoint-desktop) {
  .footerWrapper {
    padding: 0;
  }
}
