// Rounded Button Corona Summer //

.buttonCoronaSummer {
  position: relative;
  font-family: Poppins, Roboto, 'sans-serif';
  font-size: 1.8rem;
  border-radius: 1.8em;
  padding: 1.08rem 3.6rem;
  border: none;
  transition: all 0.25s;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  span {
    font-size: 1.8rem
  }

  .buttonContentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 1.8rem
    }
  }

  .buttonContent {
    display: block;
    transform: translateX(0);
    transition: transform 0.25s;
  }

  i {
    position: absolute;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 20%;
    font-size: 1.4rem;
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    i {
      opacity: 1;
      right: 1.3rem;
    }

    .buttonContent {
      display: block;
      transform: translateX(-0.8rem);
    }
  }

  &:focus {
    outline: none;
  }

  &.loading {
    i {
      opacity: 1;
      right: 1.3rem;
    }

    .buttonContent {
      display: block;
      transform: translateX(-0.8rem);
    }
  }

  // default pink button

  &.buttonPink {
    background: #e0325f;
    color: #ffffff;

    &:hover {
      cursor: pointer;
      background: #ed436f radial-gradient(circle, transparent 1%, #ed436f 1%) center/10000%;
      color: #ffffff;
    }

    &:active {
      background-color: rgba(206, 24, 71, 0.94);
      background-size: 100%;
      color: #ffffff;
    }
  }

  // button in navigation

  &.buttonNav {
    border-radius: 2rem;
    padding: 0.64rem 2.4rem;
    font-size: 1.4rem;

    i {
      font-size: 1.12rem;
    }
  }

  // button transparent

  &.buttonTransparent {
    background-color: transparent;
    color: #ffff;
    border: 0.2rem solid #ffffff;

    &:hover {
      cursor: pointer;
      background-color: transparent;
      color: #ffffff;
    }

    &:active {
      color: #e5e5e5;
      border: 0.2rem solid #e5e5e5;
      background-color: transparent;
    }
  }

  // button pink

  &.buttonWhite {
    background-color: #ffffff;
    color: #000000;

    &:hover {
      background-color: #f3f3f3;
    }

    &:active {
      background-color: #d9d9d9;
    }
  }

  // has pink borders and text
  &.pinkBordered {
    color: #e0325f;
    border: 0.1rem solid #e0325f;

    &:hover,
    &:active {
      color: #e0325f;
    }
  }

  // has grey borders and black text
  &.greyBordered {
    color: #000000;
    border: solid 0.2rem #f5f5f5;
    background: white;

    &:hover,
    &:active {
      background: #d9d9d9;
      color: #000000;
    }
  }

  // disabled
  &.disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.noBorder {
    padding: 0.64rem 2.4rem;
    font-size: 1.4rem;
    border: none;

    i {
      font-size: 1.12rem;
    }
  }
}
