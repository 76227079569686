@import '../../../styles/abstracts/grid';

.container {
  width: 100%;

  .header {
    width: 100%;
    height: 8rem;
    min-height: 8rem;
    border-bottom: solid 0.1rem #e8e8e8;
    display: flex;
    align-items: center;
    padding: 0 1.6rem;
  }

  .content {
    padding: 1.6rem 1.6rem 8rem;
  }
}

@media screen and (min-width: $breakpoint-desktop) {
  .container {

    .header {
      padding: 0 13.8rem;
    }

    .content {
      padding: 6.4rem 13.8rem;
    }
  }
}
