@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/grid';

// App navigation in footer for mobile //

.appNavigationFooterMobileWrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $color-white;
  padding: 1.08rem 0 1.32rem;
  box-shadow: 0rem -0.2rem 0.4rem rgba(0, 0, 0, 0.234375);
  z-index: 999;
}
