@import '../../../styles/abstracts/grid';
@import '../../../styles/abstracts/colors';

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem 0;
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 1.6rem;
}

.img {
  width: 100%;
  max-width: 39.86rem;
  height: auto;
  padding: 0 1.6rem;
}

.title {
  font-weight: 400;
  font-size: 3rem;
  line-height: 4.5rem;
  margin-top: 7.37rem;
}

.text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.7rem;
  margin-top: 1.6rem;
}

.linksWrapper {
  padding: 0 1.6rem;
  display: flex;
  flex-direction: column;
}

.goToHome {
  width: 100%;
  padding: 1.006rem 0;
  text-align: center;
  text-decoration: none;
  border-radius: 2.35rem;
  color: white;
  background-color: $color-pink;
}

.reload {
  margin-top: 1.6rem;
  padding: 1.006rem 0;
  border-radius: 2.35rem;
}

@media (min-width: $breakpoint-desktop) {
  .img {
    padding: 0;
  }
}
