@font-face {
    font-family: 'fitney';
    src:
        url('../../fonts/fitney.ttf?on4riv') format('truetype'),
        url('../../fonts/fitney.woff?on4riv') format('woff'),
        url('../../fonts/fitney.svg?on4riv#fitney') format('svg');
    font-weight: normal;
    font-style: normal;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'fitney' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-dumbbell:before {
    content: "\e94a";
}
.icon-x:before {
    content: "\e949";
}
.icon-timer-2:before {
    content: "\e948";
}
.icon-eye-open:before {
    content: "\e947";
}
.icon-card-cvv:before {
    content: "\e943";
}
.icon-charts-arrow-circle:before {
    content: "\e944";
}
.icon-info:before {
    content: "\e945";
}
.icon-eye-closed:before {
    content: "\e946";
}
.icon-salad:before {
    content: "\e93b";
}
.icon-kettlebell-3:before {
    content: "\e93f";
}
.icon-checks-on-board:before {
    content: "\e940";
}
.icon-chart-advanced:before {
    content: "\e941";
}
.icon-belly:before {
    content: "\e942";
}
.icon-stats-columns:before {
    content: "\e93c";
}
.icon-fork-knife:before {
    content: "\e93d";
}
.icon-checks-in-row:before {
    content: "\e93e";
}
.icon-fb-circle:before {
    content: "\e939";
}
.icon-instagram-circle:before {
    content: "\e93a";
}
.icon-instagram:before {
    content: "\e938";
}
.icon-arrow-long:before {
    content: "\e937";
}
.icon-kettlebell-2:before {
    content: "\e935";
}
.icon-clock-running:before {
    content: "\e936";
}
.icon-slim:before {
    content: "\e92d";
}
.icon-weight-bar:before {
    content: "\e92f";
}
.icon-stretching:before {
    content: "\e930";
}
.icon-lotus:before {
    content: "\e931";
}
.icon-heart-rate:before {
    content: "\e932";
}
.icon-jumping-rope:before {
    content: "\e933";
}
.icon-camera:before {
    content: "\e934";
}
.icon-statistics:before {
    content: "\e92b";
}
.icon-timer:before {
    content: "\e92c";
}
.icon-tick:before {
    content: "\e92e";
}
.icon-arrow-forward:before {
    content: "\e92a";
}
.icon-arrow-down:before {
    content: "\e929";
}
.icon-diet-plan:before {
    content: "\e928";
}
.icon-clock:before {
    content: "\e927";
}
.icon-streaming:before {
    content: "\e926";
}
.icon-play-2:before {
    content: "\e900";
}
.icon-ok:before {
    content: "\e901";
}
.icon-volume:before {
    content: "\e902";
}
.icon-full-screen:before {
    content: "\e903";
}
.icon-play-3:before {
    content: "\e904";
}
.icon-power-level:before {
    content: "\e905";
}
.icon-energy:before {
    content: "\e906";
}
.icon-user:before {
    content: "\e907";
}
.icon-play:before {
    content: "\e908";
}
.icon-list:before {
    content: "\e909";
}
.icon-button-power:before {
    content: "\e90a";
}
.icon-calendar:before {
    content: "\e90b";
}
.icon-time:before {
    content: "\e90c";
}
.icon-kettlebell:before {
    content: "\e90d";
}
.icon-zoom:before {
    content: "\e90e";
}
.icon-small-right:before {
    content: "\e90f";
}
.icon-small-left:before {
    content: "\e910";
}
.icon-height:before {
    content: "\e911";
}
.icon-calendar-date:before {
    content: "\e912";
}
.icon-todo:before {
    content: "\e913";
}
.icon-pencil-copy:before {
    content: "\e914";
}
.icon-lock:before {
    content: "\e915";
}
.icon-circle-delete:before {
    content: "\e916";
}
.icon-credit-card:before {
    content: "\e917";
}
.icon-camera-181:before {
    content: "\e918";
}
.icon-weight-scale:before {
    content: "\e919";
}
.icon-button-pause:before {
    content: "\e91a";
}
.icon-power-level1:before {
    content: "\e91b";
}
.icon-crosshair:before {
    content: "\e91c";
}
.icon-c-warning:before {
    content: "\e91d";
}
.icon-t-warning:before {
    content: "\e91e";
}
.icon-calendar-change:before {
    content: "\e91f";
}
.icon-c-info:before {
    content: "\e920";
}
.icon-triangle-left:before {
    content: "\e921";
}
.icon-triangle-right:before {
    content: "\e922";
}
.icon-volume-mute:before {
    content: "\e923";
}
.icon-circle-check:before {
    content: "\e924";
}
.icon-star:before {
    content: "\e925";
}
