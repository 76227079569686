// Fitney Footer Coronasummer - App //

@import '../../../styles/abstracts/colors';

.footerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 46.6rem;
  min-height: 6.4rem;
  margin: 3.2rem auto 0 auto;
  font-family: Poppins, Roboto, 'sans-serif';
  text-align: right;

  .copyright {
    color: $color-grey-lighter;
    white-space: nowrap;
    font-size: 1rem;
    opacity: 0.7;
  }

  @media (min-width: 1025px) {
   &.inHalfScreenComponent {
     max-width: 40.2rem;
    }
  }
}


