/* CircleLoader */

@keyframes loaderRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loaderRing {
    display: inline-block;
    position: relative;

    &.extraSmall {
        width: 1.4rem;
        height: 1.4rem;

        .loaderRingItem {
            width: 1.4rem;
            height: 1.4rem;
            border-width: 0.2rem;
        }
    }

    &.small {
        width: 1.6rem;
        height: 1.6rem;

        .loaderRingItem {
            width: 1.6rem;
            height: 1.6rem;
            border-width: 0.24rem;
        }
    }

    &.medium {
        width: 4rem;
        height: 4rem;

        .loaderRingItem {
            width: 4rem;
            height: 4rem;
            border-width: 0.4rem;
        }
    }

    &.large {
        width: 6.4rem;
        height: 6.4rem;

        .loaderRingItem {
            width: 6.4rem;
            height: 6.4rem;
            border-width: 0.64rem;
        }
    }

    &.light {
        .loaderRingItem {
            border-color: #fff #fff transparent transparent;
        }
    }

    &.dark {
        .loaderRingItem {
            border-color: #000 #000 transparent transparent;
        }
    }
}

.loaderRingItem {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-style: solid;
    border-radius: 50%;
    animation: loaderRing 1.2s cubic-bezier(0.8, 0, 0.8, 1) infinite;

    &:nth-child(1) {
        animation-delay: -0.15s;
    }

    &:nth-child(2) {
        animation-delay: -0.3s;
    }
}
