.main {
    margin-left: 3.2rem;
    margin-right: 1.6rem;
    padding: 1.6rem 0;
    min-height: 11.2rem;
    border-top: solid #f1f1f1 0.1rem;
    flex-direction: row;
}

.copyrightWrapper {
    padding-right: 1.6rem;
}

.copyright {
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #8c8592;
}

.linksAndDropdownWrapper {
    margin-bottom: 0.8rem;
}

.links {
    list-style: none;
    padding-inline-start: 0;
    white-space: nowrap;
    margin-bottom: 0.8rem;
}

.links li {
    margin-left: 1.9rem;
}

.links li:first-of-type {
    margin-left: 0;
}

.links a {
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-decoration: underline;
    color: #8c8592;
}

.languageSelect {
    margin-top: -0.8rem;
    margin-left: 1.6rem;
}

@media (min-width: 1440px) {
    .main {
        flex-direction: row;
        min-height: 5.6rem;
        flex-wrap: nowrap;
    }

    .linksAndDropdownWrapper {
        margin-bottom: -2.8rem;
    }
}
