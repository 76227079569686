@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/grid';

.main {
  display: block;
  overflow: hidden;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;

  .scrollableWrapper {
    width: 100%;
    height: 100%;
  }

  .hero {
    display: none;
  }

  .heroActionButton {
    position: relative;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.6rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (min-width: $breakpoint-desktop) {
    .scrollableWrapper {
      overflow: auto;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .hero {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 3rem 1.6rem;
    }

    .programHero {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 12.96rem 26.4rem 0;
      background-color: $color-pink;
    }

    .heroActionButton {
      width: 100%;
    }

    .imageHero {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
    }

    .programImageHero {
      position: absolute;
      bottom: 0;
      left: 15%;
      right: 10%;
      object-fit: cover;
      width: 75%;
      height: 75%;
      object-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
    }

    .content {
      padding-top: 4rem;
    }

    .header {
      justify-content: center;
    }
  }
}
