.fitneyTopBar {
    border-bottom: solid 0.1rem #e8e8e8;
    background-color: #ffffff;
}

.header {
    width: 100%;
    padding: 1.6rem;
    max-width: 121.6rem;
    margin: 0 auto;
}

.logo {
    height: 3.5200000000000005rem;
}

.loginBtn {
    font-size: 1.4rem;
    margin-right: 3rem;
    background-color: transparent;
    transition: color 0.3s ease;
}

.loginBtn:hover, .loginBtn:active {
    color: #383838;
}

.linkBlog {
    margin-left: 3.2rem;
}

@media (min-width: 1025px) {
    .logo {
        height: 4rem;
    }
}
