@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/grid';


// Layout wrapper
.playerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #0b0b0b;
  color: $color-white;
  padding: 0 1rem;

  // main component
  .mainComponentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% + 2 * 1rem);
    max-width: 118rem;
    margin: 0 -1rem;
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 0 1.6rem;
    height: min-content;
  }
}
