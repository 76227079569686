.footerDarkTheme {
    font-family: Roboto, sans-serif;
    background-color: #1e0830;
    padding: 3.2rem 1.6rem 2.8rem;
}

.main {
    max-width: 121.6rem;
    margin: 0 auto;
}

.footerTop {
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.1);
    flex-direction: column;
}

.footerTop .logoAndDropdownWrapper {
    justify-content: space-between;
    align-items: center;
}

.socialMedia {
    text-align: center;
    margin: 3.2rem 0;
}

.socialMedia a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid #ffffff;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
}

.socialMedia a:first-of-type {
    margin-right: 0.8rem;
}

.socialMedia a:hover {
    background: #ffffff42;
}

.followUs {
    display: none;
}

.listTitle {
    display: block;
    font-size: 2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #ffffff;
    margin: 3.2rem 0 1.6rem;
}

.linksWrapper ul {
    padding-inline-start: 0;
    list-style-type: none;
}

.linksWrapper ul li {
    margin: 1.6rem 0;
}

.linksWrapper ul li a {
    color: #ffffff;
    font-size: 1.6rem;
}

.emailLink {
    color: #d51446;
}

.address, .promoteText {
    color: #ffffff;
    font-size: 1.6rem;
    line-height: 1.63;
    margin-top: 1.6rem;
}

.copyright {
    font-size: 1.3rem;
    line-height: 1.85;
    color: #ffffff;
    margin-top: 3.2rem;
}

.appStoresLinks a:first-of-type {
    margin-right: 1.2rem;
}


@media (min-width: 1025px) {
    .footerDarkTheme {
        padding: 3.2rem 1.6rem 3.6rem;
    }

    .footerTop {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .footerTop .logoAndDropdownWrapper {
        justify-content: flex-start;
    }

    .footerTop .dropdownWrapper {
        position: relative;
        margin-left: 2.5em;
        padding-left: 4rem;
    }

    .footerTop .dropdownWrapper:before {
        content: "";
        position: absolute;
        left: 0;
        width: 0.1rem;
        height: 100%;
        border: 0.1rem solid #cac4d0;
        opacity: 0.5;
    }

    .footerTop .followUs {
        display: inline;
        margin-right: 1.6rem;
        color: #ffffff;
    }

    .footerBottom {
        display: flex;
    }

    .footerBottom > div {
        flex: 1;
    }

    .footerBottom .appLinksWrapper {
        flex: none;
        margin-left: 1.6rem;
        float: right;
    }

    .socialMedia {
        margin: 0 0 3.2rem;
    }

}

