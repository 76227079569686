@import '../../styles/abstracts/grid';
@import '../../styles/abstracts/colors';

.header {
  width: 100%;
  height: 8rem;
  min-height: 8rem;
  border-bottom: solid 0.1rem $color-border-gray;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}

.wrapper {
  width: 100%;
  max-width: 118rem;
  margin: 0 auto;
}

@media screen and (min-width: $breakpoint-desktop) {
  .header {
    padding: 0;
  }
}
