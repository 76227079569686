/* Fitney Magazine Layout */

.magazineWrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.magazineContainer {
    max-width: 121.6rem;
    flex: 1 1;
}

