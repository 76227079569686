@import '../../styles/abstracts/colors';

.loaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-white;
  z-index: 9999;
}

.loaderLogo {
  margin-bottom: 1.5rem;
}
