@import '../../styles/abstracts/colors';
@import '../../styles/abstracts/grid';

.root > div > div > div > div > div {
  background-color: $color-pink;
  box-shadow: 0 0.4rem 2.1rem 0 #00000029;
  padding: 1.4rem 2.4rem;
  border-radius: 1.5rem;
  max-width: 37.5rem;

  a {
    color: $color-white;
    text-decoration: underline;
  }
}

.error_icon {
  border-radius: 5rem;
  background-color: #FFFFFF;
  margin-right: 1.8rem;

  path {
    fill: $color-pink;
  }
}

