@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/consts';
@import '../../../styles/abstracts/grid';

// Player Header //

.playerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 1rem;
  width: 100%;
  max-width: 118rem;
  min-height: 7rem;
  height: 7rem;
  margin: 0 auto;

  .closeButton {
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $color-black-light;
    margin-left: 1rem;
    cursor: pointer;
  }

  .navbarLogo {
    max-width: 9rem;
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 2.08rem 0;
    min-height: 9.6rem;
    height: 9.6rem;

    .navbarLogo {
      max-width: 12.8rem;
    }
  }
}
