@import '../../../styles/abstracts/colors';

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 8rem;
  max-height: 2rem;

  &.primary {
    .ldsEllipsisItem {
      background: $color-pink;
    }
  }

  &.light {
    .ldsEllipsisItem {
      background: $color-white;
    }
  }

  &.small {
    max-width: 4rem;
    max-height: 1.2rem;

    .ldsEllipsisItem {
      width: 0.8rem;
      height: 0.8rem;

      &:nth-child(1) {
        left: 0.4rem;
      }

      &:nth-child(2) {
        left: 0.4rem;
        animation: ldsEllipsis2small 0.6s infinite;
      }

      &:nth-child(3) {
        left: 1.6rem;
        animation: ldsEllipsis2small 0.6s infinite;
      }

      &:nth-child(4) {
        left: 2.8rem;
      }
    }
  }

  .ldsEllipsisItem {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    transform-origin: center;

    &:nth-child(1) {
      left: 0.8rem;
      animation: ldsEllipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 0.8rem;
      animation: ldsEllipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 3.2rem;
      animation: ldsEllipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 5.6rem;
      animation: ldsEllipsis3 0.6s infinite;
    }
  }
}


@keyframes ldsEllipsis1 {
  0% {
    transform: translate(0, -50%) scale(0);
  }
  100% {
    transform: translate(0, -50%) scale(1);
  }
}

@keyframes ldsEllipsis3 {
  0% {
    transform: translate(0, -50%) scale(1);
  }
  100% {
    transform: translate(0, -50%) scale(0);
  }
}

@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(2.4rem, -50%);
  }
}

@keyframes ldsEllipsis2small {
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(1.2rem, -50%);
  }
}
