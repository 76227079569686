@import '../../../styles/abstracts/colors';

.landingWrapper {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  width: 100vw;
  height: 100%;
  min-height: 200vh;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
