@import '../../../styles/abstracts/grid';

.questionnaireLayout {
  margin: 0 auto;
  width: 100%;
  padding: 1.6rem 0 4rem;
  min-height: 100vh;

  .footer {
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 3.2rem 0 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer {
      max-width: 54.2rem;
      margin: 3.2rem auto 0;
      width: 100%;
    }
  }
}
