@import '../../styles/abstracts/colors';

.badge {
  padding: 0.48rem;
  background-color: $color-pink;
  border-radius: 0.32rem;
  text-align: center;
  color: $color-white;
  text-transform: uppercase;
  font-size: 1.12rem ;

  &.error {
    background-color: $color-pink-dark;
    border-radius: 8px;
    color: $color-white;
    font-size: inherit;
    text-transform: none;
    text-align: left;
    margin-bottom: 10px;
    padding: 5px 8px;
  }
}
