@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/grid';

// App nav links //

.navLinksWrapper {
  display: flex;
  padding-inline-start: 0;
  list-style-type: none;
  margin: 0;
  padding: 0.8rem;

  .navLink {
    display: flex;
    justify-content: center;
    flex: 1;
    border-radius: 1.84rem;
    cursor: pointer;

    &:hover {
      a {
        transition: color .2s;
        color: $color-accent;

        svg {
          transition: all .2s;
          fill: $color-accent;

          &.live {
            fill: transparent;

            path {
              transition: all .2s;
              stroke: $color-accent;
            }

            circle {
              transition: all .2s;
              fill: $color-accent;
            }
          }
        }
      }
    }

    &.navActive {
      a {
        color: $color-accent;

        svg {
          fill: $color-accent;

          &.live {
            fill: transparent;

            path {
              transition: all .2s;
              stroke: $color-accent;
            }

            circle {
              transition: all .2s;
              fill: $color-accent;
            }
          }
        }
      }
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: $color-black;
      font-size: 1.12rem;
      outline: none;

      &:hover {
        text-decoration: none;
        visibility: visible;
      }
    }
    span {
      font-size: 1.12rem;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    .navLink {
      flex: none;
      align-items: center;
      border-radius: 1.84rem;
      padding: 0.56rem 2.4rem;
      min-width: 11.2rem;

      &.navActive {
        background-color: $color-pink-lightest;
      }

      a {
        flex-direction: row;
        justify-content: flex-start;
        font-size: 1.4rem;

        svg {
          margin-right: 1rem;
        }
      }
      span {
        font-size: 1.4rem;
      }
    }
  }
}
