@import '../../../styles/abstracts/colors';
@import '../../../styles/abstracts/grid';

// Header App //

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color-white;
  padding: 0 1rem;
  z-index: 4;
  transition: box-shadow 0.25s ease-in-out;

  &.withShadow {
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.234375);
  }

  a,
  a:hover {
    color: $color-black;
    text-decoration: none;
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 7rem;
    max-width: 118rem;
    margin: 0 auto;
    transition: height 0.25s ease-in-out;

    .logo {
      width: 12.2rem;
    }

    .title {
      font-size: 2.4rem;
      text-transform: capitalize;
      margin: 0;
    }

    .elementsWrapper {
      display: flex;
      align-items: center;

      .headerLinks {
        display: flex;
        justify-content: space-between;

        > *:not(:last-child) {
          margin-right: 1.12rem;
        }

        .shopLink {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          &:hover {
            transition: color .2s;
            color: $color-accent !important;
          }
        }

        .iconCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: $color-pink-lightest;
          border-radius: 50%;
          padding: 0.64rem;
          max-width: 3.6rem;
          max-height: 3.6rem;
          opacity: 1;
          transition: all 0.25s ease-in-out;

          &.active {
            background-color: $color-pink;
          }
        }
      }
    }
  }

  @media (min-width: $breakpoint-desktop) {
    position: relative;
    box-shadow: none;

    &.withShadow {
      box-shadow: none;
    }

    .headerWrapper {
      padding: 2.08rem 0;
      min-height: 9.6rem;
      align-items: center;

      .headerLinks {
        > *:not(:last-child) {
          margin-right: 1.6rem !important;
        }
      }
    }
  }
}
